import React from "react";
import Layout from "../layouts/page";

export default function NotFound() {
  return (
    <Layout title="Whoops!" path="/">
      <div className="row">
        <div className="col px-5">
          <h6>Are you lost? This page doesn't exist.</h6>
        </div>
      </div>
    </Layout>
  );
}
